import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';


const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		// width: 200,
	},
	dense: {
		marginTop: 19,
	},
	menu: {
		width: 200,
	},
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   	return <Slide direction="up" ref={ref} {...props} />;
// });


export default function ConfirmationForm(props) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// console.log('props FINAL STEP', props)
	const classes = useStyles();

	function appendLeadingZeroes(n) {
		if (n <= 9) {
			return "0" + n;
		}
		return n
	}

	const monthNames = ["Ян", "Февр", "Март", "Апр", "Май", "Юни", "Юли", "Авг", "Септ", "Окт", "Ное", "Дек"];

	return (
		<React.Fragment>
			<form className={classes.container} noValidate autoComplete="off">

				<div className="reservation-success-holder">

					<div className="main-title">
						РЕЗЕРВАЦИЯТА Е НАПРАВЕНА УСПЕШНО
					</div>

					<div className="reservation-description">
						Не забравяйте, {/* <br/> */}Масата Ви ще се пази <span className="bold">не повече от 15 минути.</span>
						<br />
						При закъснение Grand Hotel Bansko си запазва правото да откаже резервацията!
					</div>

					<span className="title">Вашата резервация</span>
					<div className="reservation-info">

						<div className="leftSide">
							<div className="info">
								<span className="icon">
									<PersonIcon />
								</span>
								<span className="data">
									{props.reservationState && props.reservationState.reservationPersonName ? props.reservationState.reservationPersonName : ''}
								</span>
							</div>
							<div className="info">
								<span className="icon">
									<SmartphoneIcon />
								</span>
								<span className="data">
									{props.reservationState && props.reservationState.reservationPersonPhone ? props.reservationState.reservationPersonPhone : ''}
								</span>
							</div>
						</div>

						<div className="rightSide">

							<span className="info restaurant-info">
								<span className="icon">
									<RestaurantIcon />
								</span>
								<span className="data">
									{props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].name ? props.places[props.reservationState.reservationPlace].name : ''}
								</span>
							</span>

							<div className="info">
								<span className="icon">
									<CalendarTodayIcon />
								</span>
								<span className="data">
									{props.reservationState && props.reservationState.reservationDate && props.reservationState.reservationTime ? appendLeadingZeroes(props.reservationState.reservationDate.getDate()) + '-' + (monthNames[props.reservationState.reservationDate.getMonth()]) + "-" + props.reservationState.reservationDate.getFullYear() + " " + appendLeadingZeroes(props.reservationState.reservationTime.split(':')[0]) + ':' + appendLeadingZeroes(props.reservationState.reservationTime.split(':')[1]) + 'ч.' : ''}
								</span>
							</div>
							<div className="info">
								<span className="icon">
									<PeopleIcon />
								</span>
								<span className="data">
									{props.places && props.reservationState.reservationPeople ? props.reservationState.reservationPeople : ''}
								</span>
							</div>

							{props.reservation_free_tables_for_time === true ?
								<div className="info">
									<span className="icon">
										{/* <i className="material-icons"> */}
										{props.rooms && props.reservationState && props.reservationState.reservationRoom && props.rooms[props.reservationState.reservationRoom] && props.rooms[props.reservationState.reservationRoom].smoking === '1' ?
											<SmokingRoomsIcon />
											:
											<SmokeFreeIcon />
										}
										{/* </i> */}
									</span>
									<span className="data">
										{props.rooms && props.reservationState && props.reservationState.reservationRoom && props.rooms[props.reservationState.reservationRoom] && props.rooms[props.reservationState.reservationRoom].name ? props.rooms[props.reservationState.reservationRoom].name : ''}
									</span>
								</div>
								:
								''
							}

						</div>

					</div>

					<div className="input-fields">
					</div>

					<div>

						{/* <br/> */}

						<div className="links">
							<a target="_blank" rel="noopener noreferrer" href={props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].name ? 'https://www.google.com/maps/dir/?api=1&destination=' + props.places[props.reservationState.reservationPlace].name : ''}>Как да стигна{/* Разгледай менюто*/}</a>
						</div>

						<br />

						{(((typeof props.reservation_canceled !== "undefined" && props.reservation_canceled === true) && (typeof props.reservation_canceled_error !== "undefined" && props.reservation_canceled_error === true)) ||
							(typeof props.reservation_canceled !== "undefined" && props.reservation_canceled === false)
						) ?

							<React.Fragment>
								<Button variant="outlined" onClick={handleClickOpen}
									className="book cancel-reservation-btn"
								//  color="#ff1d1d"
								>
									Откажи резервацията
							</Button>

								<Dialog
									// color="#ff1d1d"
									open={open}
									// TransitionComponent={Transition}
									keepMounted
									onClose={handleClose}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogTitle id="alert-dialog-slide-title">Откажи резервацията</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-slide-description">
											Сигурни ли сте, че искате да анулирате току-що направената резервация ?
									</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleClose} >
											Затвори
									</Button>
										<Button onClick={props.cancelReservation} >
											АНУЛИРАЙ РЕЗЕРВАЦИЯТА
									</Button>
									</DialogActions>
								</Dialog>

							</React.Fragment>

							:
							<div className="reservation-canceled">Резервацията е отказана успешно!</div>
						}

					</div>
				</div>

			</form>

		</React.Fragment>
	);
}